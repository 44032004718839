
// const API_BASE_URL = "http://www.api.trentonforddev.com";
// const API_BASE_URL = "http://127.0.0.1:5000/";
const API_BASE_URL = "https://survey.cs.luc.edu/api";

const degrees = [
    {
        "value": "M.D.",
        "name": "M.D."
    },
    {
        "value": "M.M.",
        "name": "M.M."
    },
    {
        "value": "BSc",
        "name": "BSc"
    },
    {
        "value": "Ph.D.",
        "name": "Ph.D."
    },

]


const countries = [
    {
        

        "value": "AFG",
        "name": "Afghanistan",
        
    },
    {
        
        
        "value": "ALA",
        "name": "\u00c5land Islands",
        
    },
    {
        
        
        "value": "ALB",
        "name": "Albania",
        
    },
    {
        
        
        "value": "DZA",
        "name": "Algeria",
        
    },
    {
        
        
        "value": "ASM",
        "name": "American Samoa",
        
    },
    {
        
        
        "value": "AND",
        "name": "Andorra",
        
    },
    {
        
        
        "value": "AGO",
        "name": "Angola",
        
    },
    {
        
        
        "value": "AIA",
        "name": "Anguilla",
        
    },
    {
        
        
        "value": "ATA",
        "name": "Antarctica",
        
    },
    {
        
        
        "value": "ATG",
        "name": "Antigua and Barbuda",
        
    },
    {
        
        
        "value": "ARG",
        "name": "Argentina",
        
    },
    {
        
        
        "value": "ARM",
        "name": "Armenia",
        
    },
    {
        
        
        "value": "ABW",
        "name": "Aruba",
        
    },
    {
        
        
        "value": "AUS",
        "name": "Australia",
        
    },
    {
        
        
        "value": "AUT",
        "name": "Austria",
        
    },
    {
        
        
        "value": "AZE",
        "name": "Azerbaijan",
        
    },
    {
        
        
        "value": "BHS",
        "name": "Bahamas",
        
    },
    {
        
        
        "value": "BHR",
        "name": "Bahrain",
        
    },
    {
        
        
        "value": "BGD",
        "name": "Bangladesh",
        
    },
    {
        
        
        "value": "BRB",
        "name": "Barbados",
        
    },
    {
        
        
        "value": "BLR",
        "name": "Belarus",
        
    },
    {
        
        
        "value": "BEL",
        "name": "Belgium",
        
    },
    {
        
        
        "value": "BLZ",
        "name": "Belize",
        
    },
    {
        
        
        "value": "BEN",
        "name": "Benin",
        
    },
    {
        
        
        "value": "BMU",
        "name": "Bermuda",
        
    },
    {
        
        
        "value": "BTN",
        "name": "Bhutan",
        
    },
    {
        
        
        "value": "BOL",
        "name": "Bolivia (Plurinational State of)",
        
    },
    {
        
        
        "value": "BES",
        "name": "Bonaire, Sint Eustatius and Saba",
        
    },
    {
        
        
        "value": "BIH",
        "name": "Bosnia and Herzegovina",
        
    },
    {
        
        
        "value": "BWA",
        "name": "Botswana",
        
    },
    {
        
        
        "value": "BVT",
        "name": "Bouvet Island",
        
    },
    {
        
        
        "value": "BRA",
        "name": "Brazil",
        
    },
    {
        
        
        "value": "IOT",
        "name": "British Indian Ocean Territory",
        
    },
    {
        
        
        "value": "BRN",
        "name": "Brunei Darussalam",
        
    },
    {
        
        
        "value": "BGR",
        "name": "Bulgaria",
        
    },
    {
        
        
        "value": "BFA",
        "name": "Burkina Faso",
        
    },
    {
        
        
        "value": "BDI",
        "name": "Burundi",
        
    },
    {
        
        
        "value": "CPV",
        "name": "Cabo Verde",
        
    },
    {
        
        
        "value": "KHM",
        "name": "Cambodia",
        
    },
    {
        
        
        "value": "CMR",
        "name": "Cameroon",
        
    },
    {
        
        
        "value": "CAN",
        "name": "Canada",
        
    },
    {
        
        
        "value": "CYM",
        "name": "Cayman Islands",
        
    },
    {
        
        
        "value": "CAF",
        "name": "Central African Republic",
        
    },
    {
        
        
        "value": "TCD",
        "name": "Chad",
        
    },
    {
        
        
        "value": "CHL",
        "name": "Chile",
        
    },
    {
        
        
        "value": "CHN",
        "name": "China",
        
    },
    {
        
        
        "value": "CXR",
        "name": "Christmas Island",
        
    },
    {
        
        
        "value": "CCK",
        "name": "Cocos (Keeling) Islands",
        
    },
    {
        
        
        "value": "COL",
        "name": "Colombia",
        
    },
    {
        
        
        "value": "COM",
        "name": "Comoros",
        
    },
    {
        
        
        "value": "COG",
        "name": "Congo (Republic of the)",
        
    },
    {
        
        
        "value": "COD",
        "name": "Congo (Democratic Republic of the)",
        
    },
    {
        
        
        "value": "COK",
        "name": "Cook Islands",
        
    },
    {
        
        
        "value": "CRI",
        "name": "Costa Rica",
        
    },
    {
        
        
        "value": "CIV",
        "name": "C\u00f4te d'Ivoire",
        
    },
    {
        
        
        "value": "HRV",
        "name": "Croatia",
        
    },
    {
        
        
        "value": "CUB",
        "name": "Cuba",
        
    },
    {
        
        
        "value": "CUW",
        "name": "Cura\u00e7ao",
        
    },
    {
        
        
        "value": "CYP",
        "name": "Cyprus",
        
    },
    {
        
        
        "value": "CZE",
        "name": "Czech Republic",
        
    },
    {
        
        
        "value": "DNK",
        "name": "Denmark",
        
    },
    {
        
        
        "value": "DJI",
        "name": "Djibouti",
        
    },
    {
        
        
        "value": "DMA",
        "name": "Dominica",
        
    },
    {
        
        
        "value": "DOM",
        "name": "Dominican Republic",
        
    },
    {
        
        
        "value": "ECU",
        "name": "Ecuador",
        
    },
    {
        
        
        "value": "EGY",
        "name": "Egypt",
        
    },
    {
        
        
        "value": "SLV",
        "name": "El Salvador",
        
    },
    {
        
        
        "value": "GNQ",
        "name": "Equatorial Guinea",
        
    },
    {
        
        
        "value": "ERI",
        "name": "Eritrea",
        
    },
    {
        
        
        "value": "EST",
        "name": "Estonia",
        
    },
    {
        
        
        "value": "ETH",
        "name": "Ethiopia",
        
    },
    {
        
        
        "value": "FLK",
        "name": "Falkland Islands (Malvinas)",
        
    },
    {
        
        
        "value": "FRO",
        "name": "Faroe Islands",
        
    },
    {
        
        
        "value": "FJI",
        "name": "Fiji",
        
    },
    {
        
        
        "value": "FIN",
        "name": "Finland",
        
    },
    {
        
        
        "value": "FRA",
        "name": "France",
        
    },
    {
        
        
        "value": "GUF",
        "name": "French Guiana",
        
    },
    {
        
        
        "value": "PYF",
        "name": "French Polynesia",
        
    },
    {
        
        
        "value": "ATF",
        "name": "French Southern Territories",
        
    },
    {
        
        
        "value": "GAB",
        "name": "Gabon",
        
    },
    {
        
        
        "value": "GMB",
        "name": "Gambia",
        
    },
    {
        
        
        "value": "GEO",
        "name": "Georgia",
        
    },
    {
        
        
        "value": "DEU",
        "name": "Germany",
        
    },
    {
        
        
        "value": "GHA",
        "name": "Ghana",
        
    },
    {
        
        
        "value": "GIB",
        "name": "Gibraltar",
        
    },
    {
        
        
        "value": "GRC",
        "name": "Greece",
        
    },
    {
        
        
        "value": "GRL",
        "name": "Greenland",
        
    },
    {
        
        
        "value": "GRD",
        "name": "Grenada",
        
    },
    {
        
        
        "value": "GLP",
        "name": "Guadeloupe",
        
    },
    {
        
        
        "value": "GUM",
        "name": "Guam",
        
    },
    {
        
        
        "value": "GTM",
        "name": "Guatemala",
        
    },
    {
        
        
        "value": "GGY",
        "name": "Guernsey",
        
    },
    {
        
        
        "value": "GIN",
        "name": "Guinea",
        
    },
    {
        
        
        "value": "GNB",
        "name": "Guinea-Bissau",
        
    },
    {
        
        
        "value": "GUY",
        "name": "Guyana",
        
    },
    {
        
        
        "value": "HTI",
        "name": "Haiti",
        
    },
    {
        
        
        "value": "HMD",
        "name": "Heard Island and McDonald Islands",
        
    },
    {
        
        
        "value": "VAT",
        "name": "Vatican City State",
        
    },
    {
        
        
        "value": "HND",
        "name": "Honduras",
        
    },
    {
        
        
        "value": "HKG",
        "name": "Hong Kong",
        
    },
    {
        
        
        "value": "HUN",
        "name": "Hungary",
        
    },
    {
        
        
        "value": "ISL",
        "name": "Iceland",
        
    },
    {
        
        
        "value": "IND",
        "name": "India",
        
    },
    {
        
        
        "value": "IDN",
        "name": "Indonesia",
        
    },
    {
        
        
        "value": "IRN",
        "name": "Iran",
        
    },
    {
        
        
        "value": "IRQ",
        "name": "Iraq",
        
    },
    {
        
        
        "value": "IRL",
        "name": "Ireland",
        
    },
    {
        
        
        "value": "IMN",
        "name": "Isle of Man",
        
    },
    {
        
        
        "value": "ISR",
        "name": "Israel",
        
    },
    {
        
        
        "value": "ITA",
        "name": "Italy",
        
    },
    {
        
        
        "value": "JAM",
        "name": "Jamaica",
        
    },
    {
        
        
        "value": "JPN",
        "name": "Japan",
        
    },
    {
        
        
        "value": "JEY",
        "name": "Jersey",
        
    },
    {
        
        
        "value": "JOR",
        "name": "Jordan",
        
    },
    {
        
        
        "value": "KAZ",
        "name": "Kazakhstan",
        
    },
    {
        
        
        "value": "KEN",
        "name": "Kenya",
        
    },
    {
        
        
        "value": "KIR",
        "name": "Kiribati",
        
    },
    {
        
        
        "value": "PRK",
        "name": "Korea (Democratic People's Republic of)",
        
    },
    {
        
        
        "value": "KOR",
        "name": "Korea (Republic of)",
        
    },
    {
        
        
        "value": "KWT",
        "name": "Kuwait",
        
    },
    {
        
        
        "value": "KGZ",
        "name": "Kyrgyzstan",
        
    },
    {
        
        
        "value": "LAO",
        "name": "Lao People's Democratic Republic",
        
    },
    {
        
        
        "value": "LVA",
        "name": "Latvia",
        
    },
    {
        
        
        "value": "LBN",
        "name": "Lebanon",
        
    },
    {
        
        
        "value": "LSO",
        "name": "Lesotho",
        
    },
    {
        
        
        "value": "LBR",
        "name": "Liberia",
        
    },
    {
        
        
        "value": "LBY",
        "name": "Libya",
        
    },
    {
        
        
        "value": "LIE",
        "name": "Liechtenstein",
        
    },
    {
        
        
        "value": "LTU",
        "name": "Lithuania",
        
    },
    {
        
        
        "value": "LUX",
        "name": "Luxembourg",
        
    },
    {
        
        
        "value": "MAC",
        "name": "Macao",
        
    },
    {
        
        
        "value": "MKD",
        "name": "Macedonia (the former Yugoslav Republic of)",
        
    },
    {
        
        
        "value": "MDG",
        "name": "Madagascar",
        
    },
    {
        
        
        "value": "MWI",
        "name": "Malawi",
        
    },
    {
        
        
        "value": "MYS",
        "name": "Malaysia",
        
    },
    {
        
        
        "value": "MDV",
        "name": "Maldives",
        
    },
    {
        
        
        "value": "MLI",
        "name": "Mali",
        
    },
    {
        
        
        "value": "MLT",
        "name": "Malta",
        
    },
    {
        
        
        "value": "MHL",
        "name": "Marshall Islands",
        
    },
    {
        
        
        "value": "MTQ",
        "name": "Martinique",
        
    },
    {
        
        
        "value": "MRT",
        "name": "Mauritania",
        
    },
    {
        
        
        "value": "MUS",
        "name": "Mauritius",
        
    },
    {
        
        
        "value": "MYT",
        "name": "Mayotte",
        
    },
    {
        
        
        "value": "MEX",
        "name": "Mexico",
        
    },
    {
        
        
        "value": "FSM",
        "name": "Micronesia (Federated States of)",
        
    },
    {
        
        
        "value": "MDA",
        "name": "Moldova (Republic of)",
        
    },
    {
        
        
        "value": "MCO",
        "name": "Monaco",
        
    },
    {
        
        
        "value": "MNG",
        "name": "Mongolia",
        
    },
    {
        
        
        "value": "MNE",
        "name": "Montenegro",
        
    },
    {
        
        
        "value": "MSR",
        "name": "Montserrat",
        
    },
    {
        
        
        "value": "MAR",
        "name": "Morocco",
        
    },
    {
        
        
        "value": "MOZ",
        "name": "Mozambique",
        
    },
    {
        
        
        "value": "MMR",
        "name": "Myanmar",
        
    },
    {
        
        
        "value": "NAM",
        "name": "Namibia",
        
    },
    {
        
        
        "value": "NRU",
        "name": "Nauru",
        
    },
    {
        
        
        "value": "NPL",
        "name": "Nepal",
        
    },
    {
        
        
        "value": "NLD",
        "name": "Netherlands",
        
    },
    {
        
        
        "value": "NCL",
        "name": "New Caledonia",
        
    },
    {
        
        
        "value": "NZL",
        "name": "New Zealand",
        
    },
    {
        
        
        "value": "NIC",
        "name": "Nicaragua",
        
    },
    {
        
        
        "value": "NER",
        "name": "Niger",
        
    },
    {
        
        
        "value": "NGA",
        "name": "Nigeria",
        
    },
    {
        
        
        "value": "NIU",
        "name": "Niue",
        
    },
    {
        
        
        "value": "NFK",
        "name": "Norfolk Island",
        
    },
    {
        
        
        "value": "MNP",
        "name": "Northern Mariana Islands",
        
    },
    {
        
        
        "value": "NOR",
        "name": "Norway",
        
    },
    {
        
        
        "value": "OMN",
        "name": "Oman",
        
    },
    {
        
        
        "value": "PAK",
        "name": "Pakistan",
        
    },
    {
        
        
        "value": "PLW",
        "name": "Palau",
        
    },
    {
        
        
        "value": "PSE",
        "name": "Palestine, State of",
        
    },
    {
        
        
        "value": "PAN",
        "name": "Panama",
        
    },
    {
        
        
        "value": "PNG",
        "name": "Papua New Guinea",
        
    },
    {
        
        
        "value": "PRY",
        "name": "Paraguay",
        
    },
    {
        
        
        "value": "PER",
        "name": "Peru",
        
    },
    {
        
        
        "value": "PHL",
        "name": "Philippines",
        
    },
    {
        
        
        "value": "PCN",
        "name": "Pitcairn",
        
    },
    {
        
        
        "value": "POL",
        "name": "Poland",
        
    },
    {
        
        
        "value": "PRT",
        "name": "Portugal",
        
    },
    {
        
        
        "value": "PRI",
        "name": "Puerto Rico",
        
    },
    {
        
        
        "value": "QAT",
        "name": "Qatar",
        
    },
    {
        
        
        "value": "REU",
        "name": "R\u00e9union",
        
    },
    {
        
        
        "value": "ROU",
        "name": "Romania",
        
    },
    {
        
        
        "value": "RUS",
        "name": "Russian Federation",
        
    },
    {
        
        
        "value": "RWA",
        "name": "Rwanda",
        
    },
    {
        
        
        "value": "BLM",
        "name": "Saint Barth\u00e9lemy",
        
    },
    {
        
        
        "value": "SHN",
        "name": "Saint Helena, Ascension and Tristan da Cunha",
        
    },
    {
        
        
        "value": "KNA",
        "name": "Saint Kitts and Nevis",
        
    },
    {
        
        
        "value": "LCA",
        "name": "Saint Lucia",
        
    },
    {
        
        
        "value": "MAF",
        "name": "Saint Martin (French part)",
        
    },
    {
        
        
        "value": "SPM",
        "name": "Saint Pierre and Miquelon",
        
    },
    {
        
        
        "value": "VCT",
        "name": "Saint Vincent and the Grenadines",
        
    },
    {
        
        
        "value": "WSM",
        "name": "Samoa",
        
    },
    {
        
        
        "value": "SMR",
        "name": "San Marino",
        
    },
    {
        
        
        "value": "STP",
        "name": "Sao Tome and Principe",
        
    },
    {
        
        
        "value": "SAU",
        "name": "Saudi Arabia",
        
    },
    {
        
        
        "value": "SEN",
        "name": "Senegal",
        
    },
    {
        
        
        "value": "SRB",
        "name": "Serbia",
        
    },
    {
        
        
        "value": "SYC",
        "name": "Seychelles",
        
    },
    {
        
        
        "value": "SLE",
        "name": "Sierra Leone",
        
    },
    {
        
        
        "value": "SGP",
        "name": "Singapore",
        
    },
    {
        
        
        "value": "SXM",
        "name": "Sint Maarten (Dutch part)",
        
    },
    {
        
        
        "value": "SVK",
        "name": "Slovakia",
        
    },
    {
        
        
        "value": "SVN",
        "name": "Slovenia",
        
    },
    {
        
        
        "value": "SLB",
        "name": "Solomon Islands",
        
    },
    {
        
        
        "value": "SOM",
        "name": "Somalia",
        
    },
    {
        
        
        "value": "ZAF",
        "name": "South Africa",
        
    },
    {
        
        
        "value": "SGS",
        "name": "South Georgia and the South Sandwich Islands",
        
    },
    {
        
        
        "value": "SSD",
        "name": "South Sudan",
        
    },
    {
        
        
        "value": "ESP",
        "name": "Spain",
        
    },
    {
        
        
        "value": "LKA",
        "name": "Sri Lanka",
        
    },
    {
        
        
        "value": "SDN",
        "name": "Sudan",
        
    },
    {
        
        
        "value": "SUR",
        "name": "Suriname",
        
    },
    {
        
        
        "value": "SJM",
        "name": "Svalbard and Jan Mayen",
        
    },
    {
        
        
        "value": "SWZ",
        "name": "Swaziland",
        
    },
    {
        
        
        "value": "SWE",
        "name": "Sweden",
        
    },
    {
        
        
        "value": "CHE",
        "name": "Switzerland",
        
    },
    {
        
        
        "value": "SYR",
        "name": "Syrian Arab Republic",
        
    },
    {
        
        
        "value": "TWN",
        "name": "Taiwan, Province of China",
        
    },
    {
        
        
        "value": "TJK",
        "name": "Tajikistan",
        
    },
    {
        
        
        "value": "TZA",
        "name": "Tanzania, United Republic of",
        
    },
    {
        
        
        "value": "THA",
        "name": "Thailand",
        
    },
    {
        
        
        "value": "TLS",
        "name": "Timor-Leste",
        
    },
    {
        
        
        "value": "TGO",
        "name": "Togo",
        
    },
    {
        
        
        "value": "TKL",
        "name": "Tokelau",
        
    },
    {
        
        
        "value": "TON",
        "name": "Tonga",
        
    },
    {
        
        
        "value": "TTO",
        "name": "Trinidad and Tobago",
        
    },
    {
        
        
        "value": "TUN",
        "name": "Tunisia",
        
    },
    {
        
        
        "value": "TUR",
        "name": "Turkey",
        
    },
    {
        
        
        "value": "TKM",
        "name": "Turkmenistan",
        
    },
    {
        
        
        "value": "TCA",
        "name": "Turks and Caicos Islands",
        
    },
    {
        
        
        "value": "TUV",
        "name": "Tuvalu",
        
    },
    {
        
        
        "value": "UGA",
        "name": "Uganda",
        
    },
    {
        
        
        "value": "UKR",
        "name": "Ukraine",
        
    },
    {
        
        
        "value": "ARE",
        "name": "United Arab Emirates",
        
    },
    {
        
        
        "value": "GBR",
        "name": "United Kingdom of Great Britain and Northern Ireland",
        
    },
    {
        
        
        "value": "UMI",
        "name": "United States Minor Outlying Islands",
        
    },
    {
        
        
        "value": "USA",
        "name": "United States of America",
        
    },
    {
        
        
        "value": "URY",
        "name": "Uruguay",
        
    },
    {
        
        
        "value": "UZB",
        "name": "Uzbekistan",
        
    },
    {
        
        
        "value": "VUT",
        "name": "Vanuatu",
        
    },
    {
        
        
        "value": "VEN",
        "name": "Venezuela (Bolivarian Republic of)",
        
    },
    {
        
        
        "value": "VNM",
        "name": "Vietnam",
        
    },
    {
        
        
        "value": "VGB",
        "name": "Virgin Islands (British)",
        
    },
    {
        
        
        "value": "VIR",
        "name": "Virgin Islands (U.S.)",
        
    },
    {
        
        
        "value": "WLF",
        "name": "Wallis and Futuna",
        
    },
    {
        
        
        "value": "ESH",
        "name": "Western Sahara",
        
    },
    {
        
        
        "value": "YEM",
        "name": "Yemen",
        
    },
    {
        
        
        "value": "ZMB",
        "name": "Zambia",
        
    },
    {
        
        
        "value": "ZWE",
        "name": "Zimbabwe",
        
    }
]

export { degrees, countries, API_BASE_URL }
