import { API_BASE_URL } from "../constants";

const signIn = (token) => {
    return fetch(`${API_BASE_URL}/login`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "token": token
        })
    })
}
            

export { signIn };