/**
 * Atom that wraps around localStorage in the browser to store a login
 * token from the Django backend.
 */
import { atom, selector } from 'recoil';
import { localStorageEffect } from './localStorageEffect';

export const loginToken = atom({
  key: 'loginToken',
  default: null,
  effects_UNSTABLE: [localStorageEffect('loginToken')],
});

/** Use loginToken as a proxy to determine if user is logged in. */
export const userAuthenticatedSelector = selector({
  key: 'userAuthenticated',
  get: ({ get }) => (get(loginToken) === null ? false : true),
});
