// BackdropComponent.jsx
import React, { useState } from 'react';
import { Backdrop, Box, Button, Container, Typography } from '@mui/material';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);
const BackdropComponent = () => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <ThemeProvider theme={theme}>
                <Backdrop
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >

                    <Box
                        sx={{
                            p: 4,
                            bgcolor: 'background.paper',
                            borderRadius: 1,
                            textAlign: 'center',
                        }}
                    >
                        <Typography variant="h6" component="div">
                            Welcome to the Image Integrity Survey!
                        </Typography>
                        <Container maxWidth="md" style={{ textAlign: 'justify', marginTop: '1vh' }}>
                            <Typography variant="body2" component="div" sx={{ mt: 2 }}>

                                During this survey, you will classify scientific images as Real, Manipulated, or Synthetic content.
                                Most images in the survey are Western Blots. If you are not familiar with Western Blots, please take a minute to read about them <a href="https://phillipecardenuto.notion.site/Western-Blots-0aad5d03e3c846da8905c719344bb352?pvs=4">here</a>.



                                <p>During the survey, consider the following definitions:</p>
                                <ul>
                                    <li><strong>Real:</strong> images that are non-problematic and would not raise concerns when published in science.</li>
                                    <li><strong>Manipulated:</strong> images that have undergone suspicious or inappropriate edits, such as content cleaning or duplication.</li>
                                    <li><strong>Synthetic:</strong> images created by an artificial intelligence model.</li>
                                </ul>



                                <p>You will also be asked about the quality of the images. Consider the following:</p>
                                <ul>
                                    <li><strong>Good Quality:</strong> images that could accurately represent an original experiment.</li>
                                    <li><strong>Bad Quality:</strong> images with visual artifacts that make their interpretation difficult.</li>
                                </ul>

                                If you have any questions or encounter any issues during the survey, please contact Daniel Moreira at <a href="mailto:dmoreira1@luc.edu">dmoreira1@luc.edu</a>.
                            </Typography>
                        </Container>
                        <Typography variant="body2" component="div" sx={{ mt: 1 }}>
                            <p>Press the button to start the survey.</p>
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ mt: 1 }}
                            onClick={handleClose}
                        >
                            Start
                        </Button>
                    </Box>
                </Backdrop>
            </ThemeProvider>
        </div>
    );
};

export default BackdropComponent;
