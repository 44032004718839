import LinearProgress from '@mui/material/LinearProgress';
import Typography     from '@mui/material/Typography';
import Box            from '@mui/material/Box';

export default function LinearProgressWithLabel({value, ...props}) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress color="success" variant="determinate" {...props} value={value}/>
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="success">{`${Math.round(
            value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }