//React component boilerplate for AnnScreen
import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState  } from 'recoil';
import { useNavigate } from 'react-router-dom';
import "react-image-crop/dist/ReactCrop.css";
import '../../App.css';
import BackdropComponent  from './intro';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Backdrop           from '@mui/material/Backdrop';

import { loginToken, userAuthenticatedSelector } from '../../atoms/loginToken';
import { API_BASE_URL } from '../../constants';
import LinearProgressWithLabel from '../common/LinearProgressWithLabel';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { FormControlLabel, Box} from '@mui/material';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import PropTypes from 'prop-types';


const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" fontSize='large' />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentSatisfiedIcon color="warning" fontSize='large'/>,
    label: 'Neutral',
  },
  3: {
    icon: <SentimentVerySatisfiedIcon color="success" fontSize='large'/>,
    label: 'Very Satisfied',
  },
};

function IconContainer(props) {
    // if props is undefined, return empty span
    if (props === undefined) {
        return <span></span>;
    }
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value] ? customIcons[value].icon : null}</span>;

}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

let theme = createTheme();
theme = responsiveFontSizes(theme);

export default function AnnScreen() {


    const isLoggedIn = useRecoilValue(userAuthenticatedSelector);
    const tokenID = useRecoilValue(loginToken);
    const setLoginToken = useSetRecoilState(loginToken);

    const navigate = useNavigate();

    const [image_to_annotate, setImg] = useState({ image_path: '', image_id: '' });
    const [userAnnotation, setUserAnnotation] = useState('');
    const [userConfidence, setUserConfidence] = useState('');
    const [imageQuality, setImageQuality] = useState(-1);
    const [progress, setProgress] = useState({ annotated_images: 0, total_images: 40 });
    const [showIntro, setShowIntro] = useState(false);
    const [annotationError, setAnnotationError] = useState(false);
    const [confidenceError, setConfidenceError] = useState(false);
    const [qualityError, setQualityError] = useState(false);
    const [startTime, setStartTime] = useState(Date.now());

    //Redirects in App.jsx would be nicer but react router removed it
    useEffect(() => {
        if (!isLoggedIn) {
            // redirect to login
            console.log('not logged in');
            navigate('/login');
        }
    }, [isLoggedIn]);

    //Fetches page data from server
    useEffect(() => {
        console.log(tokenID);
        fetch(`${API_BASE_URL}/annotate_image/${tokenID}`)
            .then(res => res.json())
            .then(data => {
                console.log(data);
                if (data.status === 'success') {
                    setImg({ image_path: data.image_path, image_id: data.image_id });
                    setProgress({ annotated_images: data.annotated_images, total_images: 40 });
                    console.log(data.annotated_images);
                    if (data.annotated_images === 0) {
                         setShowIntro(true);
                    }
                } else if (data.status === 'finished_all_tasks') {
                    navigate('/finished');
                }
            })
            .catch(err => console.log(err));
    }, []);


    const handleSubmit = (e) => {

        e.preventDefault();
        console.log('submitting');
        setAnnotationError(false);
        setConfidenceError(false);
        setQualityError(false);

        if (userAnnotation === '') {
            setAnnotationError(true);
            return;
        }
        if (userConfidence === '') {
            setConfidenceError(true);
            return;
        }
        if (imageQuality === -1) {
            setQualityError(true);
            return;
        }

        submitAnnotation();

    };

    const submitAnnotation = () => {
        const endTime = Date.now();
        const timeSpent = endTime - startTime;
        // If there is currently an annotation selected, submit it
        fetch(`${API_BASE_URL}/annotate_image/${tokenID}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                image_id: image_to_annotate.image_id,
                user_annotation: userAnnotation,
                user_confidence: userConfidence,
                user_image_satisfaction: imageQuality,
                user_annotation_time: timeSpent
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
            })
            .then(() => window.location.reload())

};

    const normalize = (curr, total) => (curr / total) * 100;

    const handleConfidenceChange = (event) => {
            console.log(event.target.value);
            setUserConfidence(event.target.value);
    };
    const handleQualityChange = (event, newQuality) => {
        if (newQuality !== null) {
            console.log(newQuality);
            setImageQuality(newQuality);
        }
    }

    const handleLogout = () => {
        setLoginToken(null);
        navigate('/login');
      };

    return (
         <ThemeProvider theme={theme}>
        <div className="App">
        <Button
          variant="contained"
          color="secondary"
          onClick={handleLogout}
          style={{ position: 'absolute', top: '10px', right: '10px' }}
        >
          Logout
        </Button>
            <h2 className="header-text">Image Annotation</h2>
            <div style={{ position: 'relative' }}>
                {/* <div style={{ paddingTop: '2vh', width: '100vw', maxWidth: '700px', margin: 'auto' }}> */}

                <div style={{ display: 'flex', flexDirection: 'column', width: '100vw', maxWidth: '700px', margin: 'auto' }}>

                    <LinearProgressWithLabel value={normalize(progress.annotated_images, progress.total_images)} />
                    {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}> */}


                            <img src={image_to_annotate.image_path} alt="Annotated Image" style={{
                             maxWidth: '700px', // Ensure image does not exceed 700px in width
                            maxHeight: '700px', // Ensure image does not exceed 700px in height
                            minWidth: '700px', // Ensure image does not exceed 300px in width
                            display: 'block', // Change display to block
                            height: 'auto', // Keep aspect ratio of the image
                            width: 'auto', // Keep aspect ratio of the image
                            paddingBottom: '0px',
                            paddingTop: '0px',
                            border: '5px solid  black', //#1D9BF0',
                            objectFit: 'contain', // Ensure the image scales correctly
                            margin: 'auto' // Center the image 
                        }} />

                        <Box sx={{ height: '20px' }} /> {/* Add space from the previous container */}

                        <Container maxWidth="sm" style={{ textAlign: 'justify', marginTop: '2vh' }}> {/* Center the Stack */}
                            <Stack direction="column" spacing={2} alignItems={"center"}>
                                <Typography variant="h6" component="div" alignItems="center">This image is:</Typography>
                                <Stack direction="row" spacing={2} justifyContent="center"> {/* Add justifyContent="center" to center the items */}
                                    {/* Toggle button group */}
                                    <Button
                                        variant={userAnnotation === 'real' ? 'contained' : 'outlined'}
                                        color="primary"
                                        onClick={() => setUserAnnotation('real')}
                                    >
                                        Real
                                    </Button>
                                    <Button
                                        variant={userAnnotation === 'manipulated' ? 'contained' : 'outlined'}
                                        color="primary"
                                        onClick={() => setUserAnnotation('manipulated')}
                                    >
                                        Manipulated
                                    </Button>
                                    <Button
                                        variant={userAnnotation === 'synthetic' ? 'contained' : 'outlined'}
                                        color="primary"
                                        onClick={() => setUserAnnotation('synthetic')}
                                    >
                                        Synthetic
                                    </Button>
                                </Stack>
                            </Stack>
                        </Container>

                        <Container maxWidth="sm" style={{ textAlign: 'justify', marginTop: '2vh' }}> {/* Center the Stack */}
                            <Stack direction="column" spacing={2} alignItems={"center"}>
                                <Typography variant="h6" component="div" alignItems="center">Are you confident with your answer?</Typography>
                                <Stack direction="row" spacing={2} justifyContent="center"> {/* Add justifyContent="center" to center the items */}
                                    {/* Toggle button group */}
                                    <Button
                                        variant={userConfidence === 'yes' ? 'contained' : 'outlined'}
                                        color="primary"
                                        onClick={() => setUserConfidence('yes')}
                                    >
                                        YES
                                    </Button>
                                    <Button
                                        variant={userConfidence === 'no' ? 'contained' : 'outlined'}
                                        color="primary"
                                        onClick={() => setUserConfidence('no')}
                                    >
                                        NO
                                    </Button>
                                </Stack>
                            </Stack>
                        </Container>

                        <Container maxWidth="sm" style={{ textAlign: 'justify', marginTop: '2vh' }}> {/* Center the Stack */}
                            <Stack direction="column" spacing={2} alignItems={"center"}>
                                <Typography variant="h6" component="div" alignItems="center">Is this image good quality?</Typography>
                                <Stack direction="row" spacing={2} justifyContent="center"> {/* Add justifyContent="center" to center the items */}
                                    {/* Toggle button group */}
                                    <Button
                                        variant={imageQuality === 'yes' ? 'contained' : 'outlined'}
                                        color="primary"
                                        onClick={() => setImageQuality('yes')}
                                    >
                                        YES
                                    </Button>
                                    <Button
                                        variant={imageQuality === 'no' ? 'contained' : 'outlined'}
                                        color="primary"
                                        onClick={() => setImageQuality('no')}
                                    >
                                        NO
                                    </Button>
                                    <Button
                                        variant={imageQuality === 'uncertain' ? 'contained' : 'outlined'}
                                        color="primary"
                                        onClick={() => setImageQuality('uncertain')}
                                    >
                                        UNCERTAIN
                                    </Button>
                                </Stack>
                            </Stack>
                        </Container>
                        < br />
                        < br />
                        {annotationError ? <Typography style={{ color: 'red', fontWeight: 'bold' }}>Please annotate the image as Real, Manipulated, or Synthetic.</Typography> : <Box></Box>}
                        {confidenceError ? <Typography style={{ color: 'red', fontWeight: 'bold' }}>Please declare your confidence.</Typography> : <Box></Box>}
                        {qualityError ? <Typography style={{ color: 'red', fontWeight: 'bold' }}>Please declare how satisfied you are with the image.</Typography> : <Box></Box>}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >Submit</Button>
                    </div>
                </div>

                {/*Include a if to call BackDropComponent only if show intro equal true*/}
                
                { showIntro && <BackdropComponent />}
                {/* <Backdrop open={showIntro}>

                        <Box
                            sx={{
                                p: 4,
                                bgcolor: 'background.paper',
                                borderRadius: 1,
                                textAlign: 'center',
                            }}
                        >
                            <Typography variant="h6" component="div">
                                Welcome to our page!
                            </Typography>
                            <Typography variant="body1" component="div" sx={{ mt: 2 }}>
                                This is a simple explanation of what our page is about. Please read the information carefully.
                            </Typography>

                            <Button size="large" variant="contained" data-dismiss="player" color="primary" style={{ maxWidth: '140px', maxHeight: '70px', minWidth: '70px', minHeight: '50px' }} onClick={() => {
                                setShowIntro(false);
                            }}><b>Start</b></Button>
                    </Box>
                    </ThemeProvider>
                </Backdrop> */}
            </div>
        </ThemeProvider >
    );
}
