import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Redirect,
} from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { userAuthenticatedSelector } from './atoms/loginToken';

import Login from './components/Login';
import AnnScreen from './components/AnnScreen';
import Finished from './components/Finished';
// import Consent from "./components/Consent";
// import Leaving from "./components/Leaving";

function App() {

  const isLoggedIn = useRecoilValue(userAuthenticatedSelector);
  console.log("Islogged = "+isLoggedIn);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<AnnScreen />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login/:token" element={<Login />} />
        <Route path="/finished" element={<Finished />} />
        {/* <Route path="/consent" element={<Consent />} /> */}
        {/* <Route path="/leaving" element={<Leaving />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
