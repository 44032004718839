import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';

import Box        from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container  from '@mui/material/Container';
import Alert      from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack      from '@mui/material/Stack';

import { signIn } from '../../services/doLogin';
import { loginToken } from '../../atoms/loginToken';

export default function LoginView(){

    // Allows for alert at bottom when login fails
    const [loginFail, setLoginFail] = useState(true);

    // Set user function for user atom
    const setUser  = useSetRecoilState(loginToken);
    let { token } = useParams();

    const navigate = useNavigate();

    const validateToken = () => {
        signIn(token)
            .then(async (resp) => {
                const respJson = await resp.json();
                if (respJson.status === 'success') {
                    console.log('login success');
                    setLoginFail(false);
                    setUser(respJson.login_token);
                    // Go to previous route user was trying to access
                    navigate('/');
                } else {
                    console.log('login failed');
                    // will render alert component
                    setLoginFail(true);
                }
                
            })
    };
    if ((token) && (loginFail === true)) {
        validateToken();
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '30ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <h1 className="header-text">Scientific Integrity Survey</h1>
            <h2 className="header-text">Login Page</h2>

            <Container component="main" maxWidth="sm">
                <Stack 
                    sx={{
                        marginTop: '40px',
                        width: '100%',
                        alignItems: 'center',
                    }}
                    spacing={2}
                >
                    { loginFail  ?  
                    <Alert severity="error">
                            <AlertTitle>Login Failed</AlertTitle>
                            Invalid Token: <strong> {token}</strong> <br />
                            Please use the exact link from the email sent to you.
                        </Alert> :
                        <Box></Box>
                    }
                    </Stack>
                    <Stack 
                        sx={{
                            marginTop: '40px',
                            width: '100%',
                            alignItems: 'center',
                        }}
                        spacing={2}
                    >                    
                    <Box sx={{marginTop: '20px'}}>
                        <Typography>Not registered? <br /> Fill out this <a href="https://forms.gle/hiQWUeBbetQ2RvdD9" target="_blank" rel="noopener noreferrer">FORMS</a> to receive a new token.</Typography>
                    </Box>
                </Stack>
            </Container>
        </Box>
    );
};
