import React from 'react';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Container, Box, Stack } from '@mui/material';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { loginToken } from '../../atoms/loginToken';

let theme = createTheme();
theme = responsiveFontSizes(theme);

export default function FinishScreen() {
  const setLoginToken = useSetRecoilState(loginToken);
  const navigate = useNavigate();

  const handleLogout = () => {
    setLoginToken(null);
    document.cookie = 'loginToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
    navigate('/login');
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {/* <Container maxWidth="sm" style={{ marginTop: '20vh' }}> */}
          <Container maxWidth="md" style={{ textAlign: 'justify', marginTop: '20vh' }}>
          <Stack spacing={3} alignItems="center">
            <Typography variant="h4" component="div" gutterBottom>
              All Tasks Completed!
            </Typography>
            <Typography variant="h6" component="div" gutterBottom>
              Thank you for participating in our study! We would like to remind you that this experiment
              exposed you to pristine and doctored scientific images. Our purpose was to assess how well
              people can differentiate between pristine and doctored content.
              To learn more about the topic, please refer to this <a href="https://phillipecardenuto.notion.site/Western-Blots-0aad5d03e3c846da8905c719344bb352?pvs=4">document</a>.
            </Typography>
            <Typography variant="h6" component="div" gutterBottom>
              Thank you for your careful attention throughout the survey. We kindly request that you refrain
              from discussing this experiment with other volunteers to ensure the integrity of the research and
              prevent any potential bias in future participants.
            </Typography>

            <Typography variant="h6" component="div" gutterBottom>
              <p>Contacts and Questions:</p>
              If you have questions about this research study, please contact Dr. Daniel Moreira at
              dmoreira1@luc.edu. If you have questions about your rights as a research participant, please
              contact the Loyola University Office of Research Services at (773) 508-2689.
              
            </Typography>
            <Button variant="contained" color="secondary" onClick={handleLogout}>
              Logout
            </Button>
          </Stack>
        </Container>
      </div>
    </ThemeProvider>
  );
}

// export default function CompScreen() {
//     return (
//         <h1 style={{"textAlign":"center"}}>All Tasks Completed! Thank you for taking the time to help with our study.</h1>
//     )
// }